/**
 * TODOS
 */
export const ADD_TODO = 'ADD_TODO';
export const REMOVE_TODO = 'REMOVE_TODO';
export const MODIFY_TODO_STATE = 'MODIFY_TODO_STATE';
export const MODIFY_TODO_TEXT = 'MODIFY_TODO_TEXT';
export const MODIFY_NOTE = 'MODIFY_NOTE';

/**
 * WEATHER
 */
export const SET_WEATHER_API = 'SET_WEATHER_API';
export const SET_UNITS = 'SET_UNITS';
export const SET_CITY = 'SET_CITY';
export const SET_COORDINATES = 'SET_COORDINATES';
export const SET_STATE = 'SET_STATE';

/**
 * TOOLBAR
 */
export const SET_QUOTE = 'SET_QUOTE';
export const ADD_SHORTCUT = 'ADD_SHORTCUT';
export const REMOVE_SHORTCUT = 'REMOVE_SHORTCUT';
export const SET_REMOTE_SHORTCUTS = 'SET_REMOTE_SHORTCUTS';

/**
 * SETTINGS
 */
export const SET_OPTION = 'SET_OPTION';
export const SET_BACKGROUND = 'SET_BACKGROUND';

/**
 * PREFERENCES
 */
export const SET_OPTION_SELECTED = 'SET_OPTION_SELECTED';
export const REMOVE_OPTION_SELECTED = 'REMOVE_OPTION_SELECTED';
export const SET_STEP_SELECTED = 'SET_STEP_SELECTED';
export const SET_SINGLE_OPTION_SELECTED = 'SET_SINGLE_OPTION_SELECTED';
export const SET_PREFERENCES_INIT = 'SET_PREFERENCES_INIT';
export const SET_COLLAPSED = 'SET_COLLAPSED';
export const INCREASE_INIT_COUNTER = 'INCREASE_INIT_COUNTER';

/**
 * TRACKING
 */
export const GET_TRACKING_RESULT = 'GET_TRACKING_RESULT';
export const GET_TRACKING_RESULT_SUCCESS = 'GET_TRACKING_RESULT_SUCCESS';
export const GET_TRACKING_RESULT_ERROR = 'GET_TRACKING_RESULT_ERROR';

/**
 * APP
 */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SEARCHING_RESULTS = 'SEARCHING_RESULTS';
export const SEARCHING_MORE_RESULTS = 'SEARCHING_MORE_RESULTS';
export const APP_SEARCH_RESULTS = 'APP_SEARCH_RESULTS';
export const APP_SEARCH_MORE_RESULTS = 'APP_SEARCH_MORE_RESULTS';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const SET_APP_TOKEN = 'SET_APP_TOKEN';
export const GET_FAVORITES = 'GET_FAVORITES';
export const SELECT_PROVIDER = 'SELECT_PROVIDER';
export const GET_PROVIDERS = 'GET_PROVIDERS';

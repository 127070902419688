import React from 'react';

import { mainSite } from '../../util';
import logo from '../../images/logo.png';

import './style.scss';

function Footer() {
	return (
		<footer>
			<ul className="legal-links">
				<li>
					<img alt="Default Search Tool" className={'logo'} src={logo} />
				</li>
				<li>
					<a target="_blank" href={`${mainSite}/Privacy.html`}>
						Privacy Policy
					</a>
				</li>
				<li>
					<a target="_blank" href={`${mainSite}/Terms.html`}>
						EULA
					</a>
				</li>

				<li>
					<a target="_blank" href={`${mainSite}/Endorsement.html`}>
						DMCA Policy
					</a>
				</li>
				<li>
					<a target="_blank" href={`${mainSite}/Uninstall.html`}>
						Uninstall
					</a>
				</li>
				<li>
					<a target="_blank" href={`${mainSite}/Contact.html`}>
						Contact Us
					</a>
				</li>
			</ul>
		</footer>
	);
}

export default Footer;

import React from 'react';

import Form from '../../components/Form';

import './style.scss';

function Home() {
	return (
		<div className="toolbar">
			<section className="main-content">
				<Form target="_blank" placeholder={'Search or type a URL'} />
			</section>
		</div>
	);
}

export default Home;

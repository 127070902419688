import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Form from '../Form/';

import './style.scss';

function Header(props) {
	const location = useLocation();
	return (
		<header>
			<ul className="d-flex list-unstyled m-0">
				<li>
					<Form type="header" target="_blank" placeholder={'Search'} />
				</li>
			</ul>
		</header>
	);
}
const mapStateToProps = (state) => {
	return {
		preferences: state.preferences.init,
	};
};

export default connect(mapStateToProps, {})(Header);

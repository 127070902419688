import React, { useEffect, useState } from 'react';

import { EventEmitter } from '../../events';

import axios from 'axios';
import { dashboard } from '../../util';

import './style.scss';
import { useLocation } from 'react-router';

function getChromeVersion() {
	var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
	return raw ? parseInt(raw[2], 10) : false;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
  }

function Keep() {
	const [display, setDisplay] = useState(localStorage.getItem('display_keep_it1') || '');
	const query = useQuery();
	const u = query.get('u');
	console.log(u);
	
	useEffect(() => {
		localStorage.setItem('display_keep_it1', 'false');
		var extraClass = [];
		if (navigator.userAgent.indexOf('Macintosh') !== -1) {
			extraClass.push('mac');
		}

		const version = getChromeVersion();
		if (version <= 85) {
			setTimeout(() => {
				setDisplay('false');
			}, 10000);
		}

		if (display === '') {
			// conversion
			var uid = localStorage.getItem('uid');
			if(!uid && u) {
				uid = u;
				localStorage.setItem('uid', u);
			}
			var first_search = localStorage.getItem('first_search');

			axios
				.get(`${dashboard}/installed`, {
					params: {
						u: uid ? uid : '',
						kw: first_search ? first_search : '',
					},
				})
				.then((res) => {
					var __pixels = document.createElement('div');
					__pixels.innerHTML = res.data.pixels;
					document.body.appendChild(__pixels);
				});
		}
		setTimeout(() => {
			if (getChromeVersion() > 85) {
				EventEmitter.dispatch('first_load');
				setDisplay('false');
			}
		}, 1000);
	}, []);

	return null;
}

export default Keep;

import React, { useEffect, useState } from 'react';

import { HashRouter as Router, Switch, Route, useHistory } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Keep from './components/Keep';

import Home from './pages/Home/';

import { setPreferencesInit, increaseInitCounter } from './actions/PreferencesActions';

import './App.scss';
import { connect } from 'react-redux';

function App({ preferences, setPreferencesInit, increaseInitCounter, token }) {
	const [display, setDisplay] = useState(localStorage.getItem('display_keep_it1') || '');

	useEffect(() => {
		// localStorage.setItem('uid', 'cef954499dcd2d2d93e7f4801cf568ae');
		if (display !== '' && !preferences) {
			setPreferencesInit(true);
		}
	}, [display]);

	useEffect(() => {
		// increaseInitCounter();
	}, [token]);

	return (
		<Router>
			<Header />
			<Switch>
				<Route path="/">
					<Home />
				</Route>
			</Switch>
			<Footer />
			<div onClick={() => (document.body.className = '')} className="overlay"></div>
			<Keep />
		</Router>
	);
}

var mapStateToProps = (state) => {
	return {
		token: state.app.token,
		preferences: state.preferences.init,
	};
};

export default connect(mapStateToProps, {
	setPreferencesInit,
	increaseInitCounter,
})(App);
